@import "../scss/variables";
@import "../scss/helpers";

body, html {
  margin: 0;
  padding: 0;
}

html,
body,
.app-container,
.app-inner {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body,
.app-container,
.app-inner,
.app-content {
  @extend %noshrink-item;
}

* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}

.loader-wrapper {
  flex: 1; //parent needs to be display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-container {
  align-items: center;
  padding: 20px;
  font-family: $text-primary-family;
  background-image: $gradient-primary;
}

.app-inner {
  width: 100%;
  max-width: $dim-max-read-width;
  &.login,
  &.user {
    @extend %app-illustration;
    .app-content {
      align-items: flex-start;
    }
  }
  &.payment-plan {
    margin-bottom: 70px;
  }
}

.copyright-line {
  padding-top: 10px;
  color: rgba(white, .8);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a {
    color: white;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.app-content {
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(white, .1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgotpass-view {
  width: 100%;
  @media (min-width: 800px) {
    max-width: 450px;
  }
  footer {
    padding: 20px 0;
  }
}

.content-wrapper {
  position: relative;
  transition: all .5s;
  padding: 20px;
  background: #fff;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  &:after {
    background-size: contain;
    background-position: center;
    position: absolute;
    pointer-events: none;
    content: "";
    width: 100px;
    height: 60px;
    background-repeat: no-repeat;
    opacity: .6;
    left: 50%;
  }
  @media (max-width: 1024px) {
    padding-bottom: 100px;
    &:after {
      transform: translateX(-50%);
      bottom: 30px;
    }
  }
  @media (min-width: 1025px) {
    &[class$="-img"] {
      padding-left: 100px;
    }
    &:after {
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.loan-amount-img:after {
    background-image: url("../images/icons/property-price.svg");
  }
  &.participation-img:after {
    background-image: url("../images/icons/savings.svg");
  }
  &.interest-rate-img:after {
    background-image: url("../images/icons/calc.svg");
  }
  &.initial-cost-img:after {
    background-image: url("../images/icons/savings.svg");
  }
  &.additional-costs-img:after {
    background-image: url("../images/icons/costs.svg");
  }
  &.review-save-img:after {
    background-image: url("../images/icons/payment-plan.svg");
  }
}

.talking-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 20px;
  font-weight: bold;
  background-color: $color-secondary;
  color: white;
  .talking-heading {
    margin: 0;
  }
  h2, p {
    text-align: center;
    max-width: 800px;
  }
  a {
    color: white;
  }
  p {
    margin: 0;
  }
  h2 {
    margin: 5px 0;
  }
}

.scroller {
  max-width: 100%;
  overflow: auto;
}

//TODO see what to do with this
.help-block {
  color: $color-danger;
}

.center-message {
  align-items: stretch;
  justify-content: center;
}

.broken-page {
  display: flex;
  justify-content: center;
  text-align: center;
  color: $color-primary;
  background-color: white;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
  flex: 1;
  h2 {
    margin-bottom: 0;
  }
  a {
    color: $color-secondary;
  }
  p {
    font-weight: bold;
  }
}

.zero-calculations-state {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  color: $color-primary;
  a {
    color: $color-secondary;
  }
}

.main-bottom-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  padding: 10px;
  z-index: 50;
  align-items: center;
  .footer-inner {
    min-height: 62px;
    width: 100%;
    align-items: center;
    max-width: $dim-max-read-width;
    justify-content: space-between;
    .right-group {
      align-items: center;
      justify-content: flex-end;
      .label {
        white-space: nowrap;
      }
    }
  }
  .home-link {
    flex: none;
    margin-right: 20px;
  }
  .app-logo.mini {
    height: 100%;
  }
  .reset-payment-button {
    margin: 10px;
  }
}