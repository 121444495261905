@mixin has-tags($tag-height: 10px, $tag-width: 30px, $tag-color: $color-accent, $tab-border-width: 1px) {
  padding-bottom: $tag-height;
  &__tab {
    position: relative;
    &.active:after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% + #{$tab-border-width});
      content: "";
      width: 0;
      height: 0;
      border-left: calc(#{$tag-width}/2) solid transparent;
      border-right: calc(#{$tag-width}/2) solid transparent;
      border-top: calc(#{$tag-height} - 2px) solid $tag-color;
    }
  }
}