@import "../scss/reset";
@import "../scss/variables";
@import "../scss/mixins";
@import "../scss/navtabs";
@import "../scss/gridlist";
@import "../scss/form-organisms";
@import "../scss/dismissable-toolbar";

.calculator-view {
  width: 100%;
  .navtabs {
    @include has-tags(10px, 30px, $color-accent, 0px);
  }
  .amount {
    margin: 0;
    white-space: nowrap;
    font-family: $text-number-family;
  }
  .label {
    display: block;
    font-size: 12px;
  }
  .tabitems {
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    li {
      display: inline-block;
      padding: 0 5px;
      &:not(:last-child) {
        margin-right: 5px;
        .label {
          padding-right: 5px;
        }
        .amount {
          padding-right: 5px;
          border-right: 1px dashed white;
        }
      }
    }
  }
  .active .tabitems li:not(:last-child) .amount {
    border-right: 1px dashed $color-primary;
  }
  .name-input-group {
    align-items: stretch;
    padding: 10px;
    div[class^="MuiInputBase-root"] {
      color: $color-secondary-lightest;
      flex: 1;
      font-size: 150%;
      @media (max-width: 600px) {
        margin-bottom: 10px;
      }
    }
    h1 {
      color: white;
      margin: 0;
      padding: 10px;
      border: 1px dotted white;
    }
  }
  .talking-header {
    min-height: 60px;
  }
  [class$="-field"] {
    font-weight: bold;
    padding: 10px;
    color: white;
    text-align: right;
  }
  .costs-field {
    background-color: $color-grey;
  }
  .total-field {
    background-color: $color-accent;
  }
  .total-sub-field {
    background: $gradient-primary;
  }
  .participation-field {
    background-color: $color-secondary;
  }
  .loan-field {
    background-color: $color-danger;
  }
  .subtotal-field {
    line-height: 60px;
    background-color: $color-danger;
  }
  .row-total {
    background-color: $color-grey-lightest;
  }
  .total-costs-field {
    background-color: $color-primary-darkest;
  }
  .super-total-field {
    background: $gradient-primary;
  }
  .month-pointer {
    position: sticky;
    left: 0;
    justify-content: center;
    margin-right: 20px;
    @media screen and (min-width: 1024px) {
      max-width: 150px;
    }
    .order-no {
      color: $color-accent;
      font-weight: bold;
    }
  }
  .add-row {
    justify-content: center;
    padding: 10px;
  }
  .interdependent-elements {
    padding: 10px 0 20px;
    @media (min-width: 800px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
    svg {
      color: $color-accent;
      margin: 30px 20px 20px;
    }
  }
  .costs-calc {
    min-width: 480px;
  }
  footer.ctas {
    padding: 20px;
    background-color: $color-grey-lightest;
    &.row-xs {
      > *:not(:last-child) {
        margin-right: 20px;
      }
    }
    &.stage-0 {
      justify-content: flex-end;
    }
    &:not(.stage-0) {
      justify-content: space-between;
    }
  }
  @media (min-width: 601px) {
    .big-input [class^="MuiInputBase-root"] {
      font-size: 150%;
    }
  }
  .results-footer {
    justify-content: flex-end;
  }
}

.base-plan {
  border: 1px dashed white;
  margin-bottom: 5px;
  padding: 5px;
  color: white;
  .title-bar {
    border-bottom: 1px solid white;
    margin-bottom: 5px;
  }
}

