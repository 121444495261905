//overriding display: inline-flex for text inputs
div[class^="MuiInputBase-root"],
div[class^="MuiFormControl-root"] {
  display: flex;
}

div[class^="MuiInputAdornment-root"] p {
  color: $color-accent;
  font-weight: 600;
}

[class^="MuiList-root"] [class^="MuiSvgIcon-root"] {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  font-size: 16px;
}

[class^="MuiCircularProgress-root"] {
  margin-left: 5px;
}

[class^="MuiSvgIcon-root"] {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}

[class^="MuiDialog-root"] {
  font-family: $text-primary-family;
}

[class^="MuiPaper-root"] {
  @media (min-width: 768px) {
    min-width: 480px;
  }
}

//selected row styles
body .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: $color-primary-lightest;
}

.MuiTableBody-root > tr {
  &:nth-child(odd) {
    background-color: $color-grey-lightest;
  }
  &:nth-child(even) {
    background-color: white;
  }
  &.selected-row {
    background-color: $color-accent-lightest;
  }
  @media (min-width: 600px) {
    &:hover {
      .MuiButtonBase-root {
        opacity: 1;
        transform: scale(1);
        &.Mui-disabled {
          opacity: .5;
          cursor: not-allowed;
        }
      }
    }
    .MuiButtonBase-root {
      transition: all 300ms ease-in-out;
      transform: scale(.5);
      opacity: 0;
    }
  }
  .MuiButtonBase-root {
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
    width: 36px;
    height: 36px;
    font-size: 24px;
    padding: 5px;
    color: $color-grey-darkest;
    border: 2px solid $color-grey-darkest;
    &.Mui-disabled {
      border: 2px solid $color-grey;
      overflow: visible;
      &:before {
        top: 50%;
        left: 2px;
        right: 2px;
        height: 2px;
        transform: rotate(45deg);
      }
      &:after {
        left: 50%;
        top: 2px;
        bottom: 2px;
        width: 2px;
        transform: rotate(45deg);
      }
      &:after,
      &:before {
        content: "";
        position: absolute;
        background-color: $color-grey-darkest;
      }
      .MuiIcon-root {
        color: $color-grey;
      }
    }
  }
  //action icons
  .trash-icon {
    color: $color-accent;
  }
  .euro-symbol {
    color: $color-secondary;
  }
}

body [class*="MuiInputBase-input"] {
  padding-left: 5px;
}

[class*="MTableHeader-header"] {
  background-color: transparent;
}

[class*="MuiMenuItem-gutters"].has-link a {
  text-decoration: none;
}

[class*="MuiMenuItem-gutters"].has-button {
  padding: 0;
  a, button {
    flex: 1;
    height: 48px;
    line-height: 48px;
    padding: 0 20px;
    border-radius: 0;
    text-transform: none;
    text-decoration: none;
    font-weight: normal;
    font-size: 1rem;
    &:not(.centered) {
      text-align: left;
      justify-content: flex-start;
    }
  }
}

body .MuiButton-root {
  line-height: 1;
  min-height: 30px;
}

.MuiButton-label > svg {
  @media (min-width: 600px) {
    margin-right: 10px;
  }
}

.MuiFormControlLabel-root {
  .MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked {
    color: $color-secondary;
    + .MuiTypography-root button {
      color: $color-secondary;
    }
  }
  button {
    font-weight: 600;
  }
}

body [class*="MuiButton-root"] {
  min-width: 30px;
}


.MuiPaper-rounded {
  border-radius: 0 !important;
}

.link-button {
  text-decoration: none;
}