//LAYOUT HELPERS
%noshrink-item {
  flex: 1 0 auto;
}

.column, .row {
  &-xs {
    display: flex;
  }
  &-md {
    @media (min-width: 768px) {
      display: flex;
    }
  }
  &-lg {
    @media (min-width: 1024px) {
      display: flex;
    }
  }
  &.wrap {
    flex-wrap: wrap;
  }
  @media (min-width: 601px) {
    display: flex;
  }
}
.column {
  flex-direction: column;
  &-xs {
    flex-direction: column;
  }
}
.hidden {
  &-xs {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
.row {
  flex-direction: row;
}

.flex {
  @extend %noshrink-item;
}
.flex-none {
  flex: none;
}

.clickable {
  cursor: pointer;
}