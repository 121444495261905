@import "variables";

.navtabs {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  font-weight: bold;
  &:not(.not-clickable) .navtabs__tab {
    cursor: pointer;
    &:hover {
      background-color: rgba(white, .1);
    }
  }
  &__tab {
    color: white;
    &:not(.has-link) {
      padding: 10px;
    }
    &:not(:last-child) {
      border-right: 1px solid white;
    }
    &.active {
      background-color: white;
      color: $color-primary;
      pointer-events: none;
    }
    a {
      display: block;
      height: 100%;
      padding: 10px;
    }
    p {
      margin: 0;
      font-weight: normal;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}