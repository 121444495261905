.form-molecule {
  margin-bottom: 20px;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 10px;
  }
}

.hint-text {
  color: $color-accent;
  margin: 20px 10px;
  display: inline-block;
  font-weight: bold;
}

.form-atom {
  @media (max-width: 600px) {
    display: flex !important;
  }
  @media (min-width: 601px) {
    &:not(.mini) {
      min-width: 350px;
    }
    &.mini {
      min-width: 200px;
    }
  }
}