@import "../scss/variables";

.app-header {
  color: white;
  &__inner {
    margin: 10px 0;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: $dim-max-read-width;
    @media (min-width: 601px) {
      height: 100px;
    }
  }
  &__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    padding: 5px;
    &:last-of-type {
      justify-content: flex-end;
    }
  }
  .app-logo {
    flex: none;
    margin: 5px;
    &-container {
      @media (max-width: 600px) {
        transform: scale(.6);
      }
    }
  }
  .user-name {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
  }
  .lang-menu {
    align-items: center;
    .text-label {
      display: inline-block;
      margin-right: 5px;
      font-size: 12px;
      font-weight: 600;
      color: rgba(white,.8)
    }
    button, a {
      text-transform: lowercase;
    }
  }
}