//COLORS
$color-primary: #4B5EAA;
$color-secondary: #26c37c;
$color-grey: #818181;
$color-accent: #FF344D;
$color-danger: #ab1046;
//tints
$color-primary-darker: #38548C;
$color-primary-darkest: #00485F;
$color-primary-lightest: #D2DFFF;
$color-secondary-lightest: #b9ffdf;
$color-grey-darkest: #474747;
$color-grey-lightest: #E8E8E8;
$color-accent-lightest: #FFE1EB;

//GRADIENTS
$gradient-primary: linear-gradient(#037BA3 0%, #782666 100%);

//DIMENSIONS
$dim-max-read-width: 1440px;

//TYPOGRAPHY
$text-primary-family: 'PT Sans', sans-serif;
$text-number-family: 'PT Mono', monospace;
$text-caption-family: 'PT Serif Caption', serif;

//ILLUSTRATION
%app-illustration {
  @media(min-width: 800px) {
    background-image: url("../images/debunker-illustration.svg");
    background-size: calc(100% - 470px) auto;
    background-repeat: no-repeat;
    background-position: center right;
  }
}

//font-family: 'PT Serif Caption', serif;
//font-family: 'PT Serif', serif;
//font-family: 'PT Sans', sans-serif;
//font-family: 'PT Mono', monospace;