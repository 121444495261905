.gridlist {
  width: 100%;
  min-width: 480px;
  background-color: white;
  display: table;
  &__header {
    font-weight: bold;
    color: $color-primary;
    .gridlist__cell {
      text-align: left;
      display: table-cell;
      vertical-align: bottom;
      font-family: $text-primary-family;
    }
  }
  &__subheader {
    background-color: $color-grey-lightest;
    font-weight: bold;
    .gridlist__cell {
      display: table-cell;
      vertical-align: bottom;
      font-family: $text-primary-family;
    }
  }
  &__row,
  &__header {
    display: table-row;
  }
  &__cell {
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
    &.number {
      text-align: right;
      font-family: $text-number-family;
    }
    &.actions {
      width: 50px;
      text-align: center;
      &.delete {
        color: $color-accent;
      }
    }
  }
  &__row {
    &.clickable:hover {
      background-color: $color-accent-lightest;
    }
    &:nth-child(even) {
      background-color: $color-primary-lightest;
    }
  }
  &__footer {
    padding: 10px;
    text-align: center;
  }
  &__container {
    width: 100%;
    max-width: 100%;
    .scroller {
      background-color: white;
      position: relative;
    }
    header {
      align-items: center;
      h2 {
        color: white;
      }
    }
  }
}

table.dbnkr-table {
  min-width: 500px;
  max-width: 100%;
  .header {
    font-weight: bold;
    color: $color-primary;
    .cell {
      text-align: left;
      display: table-cell;
      vertical-align: bottom;
      font-family: $text-primary-family;
    }
  }
  .subheader, .sub-footer {
    font-weight: bold;
    .cell:not(.edit) {
      display: table-cell;
      vertical-align: bottom;
      font-family: $text-primary-family;
      background-color: $color-grey-lightest;
    }
  }
  .row,
  .header {
    display: table-row;
  }
  .cell {
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
    &.number {
      text-align: right;
      font-family: $text-number-family;
    }
    &.actions {
      width: 50px;
      text-align: center;
      &.delete {
        color: $color-accent;
      }
    }
  }
  tbody.part {
    border: 1px solid $color-primary-lightest;
    > .row {
      &:nth-child(even) td:not(.edit) {
        background-color: $color-primary-lightest;
      }
    }
  }
  .footer {
    padding: 10px;
    text-align: center;
  }
  .edit {
    background-color: transparent;
  }
}