h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

@media (max-width: 600px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }
}